.landing-page {
  font-family: Arial, sans-serif;
  color: #333;
  padding: 20px;
  text-align: center;
  background-color: #f5f5f5;

  .header {
    background-color: #007bff;
    color: white;
    padding: 50px;
    border-radius: 8px;
    margin-bottom: 30px;

    h1 {
      font-size: 3rem;
      margin-bottom: 20px;
    }

    p {
      font-size: 1.5rem;
      line-height: 1.8;
      margin-bottom: 20px;
    }

    .access-button {
      background-color: #28a745;
      color: white;
      font-size: 1.5rem;
      font-weight: bold;
      padding: 15px 30px;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #218838;
      }

      &:active {
        background-color: #1e7e34;
      }
    }
  }

  .content {
    .section {
      background: white;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      padding: 30px;
      margin-bottom: 30px;
      text-align: left;

      h2 {
        font-size: 2rem;
        margin-bottom: 20px;
        color: #007bff;
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          margin-bottom: 12px;
          font-size: 1.2rem;

          &:before {
            content: '✔️';
            margin-right: 10px;
          }
        }
      }
    }

    .professionals {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      gap: 20px;

      .professional-card {
        background-color: #f9f9f9;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        flex: 1;
        max-width: 300px;
        text-align: center;

        .professional-image {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          margin-bottom: 15px;
        }

        h3 {
          font-size: 1.5rem;
          color: #333;
        }

        p {
          font-size: 1rem;
          margin: 5px 0;
        }
      }
    }
  }
}
