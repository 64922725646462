.dashboard {
  padding: 2rem;

  .title {
    font-size: 2rem;
    color: #007bff;
    margin-bottom: 1rem;
  }

  .group-table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      border: 1px solid #ddd;
      padding: 0.75rem;
      text-align: left;
    }

    th {
      background-color: #f2f2f2;
    }

    .view-button {
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 4px;
      padding: 0.5rem 1rem;
      cursor: pointer;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
}

.add-group-button {
  background-color: #28a745;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 1rem;

  &:hover {
    background-color: #218838;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  }

  &:active {
    background-color: #1e7e34;
  }
}
