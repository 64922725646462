.group-details {
  padding: 2rem;

  .title {
    font-size: 2rem;
    color: #007bff;
    margin-bottom: 1rem;
  }

  .people-table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      border: 1px solid #ddd;
      padding: 0.75rem;
      text-align: left;
    }

    th {
      background-color: #f2f2f2;
    }

    .add-score-button {
      background-color: #28a745;
      color: white;
      border: none;
      border-radius: 4px;
      padding: 0.5rem 1rem;
      cursor: pointer;

      &:hover {
        background-color: #218838;
      }
    }
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-content {
      background: #fff;
      padding: 2rem;
      border-radius: 8px;
      width: 300px;
      text-align: center;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

      h2 {
        margin-bottom: 1rem;
        color: #333;
      }

      .modal-input {
        width: 100%;
        padding: 0.5rem;
        margin-bottom: 1rem;
        border: 1px solid #ddd;
        border-radius: 4px;
      }

      .modal-actions {
        display: flex;
        justify-content: space-between;

        .modal-button {
          background-color: #007bff;
          color: #fff;
          border: none;
          border-radius: 4px;
          padding: 0.5rem 1rem;
          cursor: pointer;

          &:hover {
            background-color: #0056b3;
          }
        }

        .modal-button-cancel {
          background-color: #d9534f;
          color: #fff;
          border: none;
          border-radius: 4px;
          padding: 0.5rem 1rem;
          cursor: pointer;

          &:hover {
            background-color: #c9302c;
          }
        }
      }
    }
  }
}

.add-person-button {
  background-color: #28a745;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 1rem;

  &:hover {
    background-color: #218838;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  }

  &:active {
    background-color: #1e7e34;
  }

  &:first-child {
    margin-right: 10px;
  }
}
