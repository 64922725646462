.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-content {
    background: #fff;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
    padding: 1.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;

    h2 {
      margin-bottom: 1rem;
      color: #333;
    }

    .total-points {
      font-size: 1.2rem;
      margin-bottom: 1rem;
      color: #007bff;
    }

    .checkbox-group {
      margin-bottom: 1rem;
      text-align: left;

      .checkbox-label {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;

        input {
          margin-right: 0.5rem;
        }
      }
    }

    .modal-actions {
      display: flex;
      justify-content: space-between;

      .confirm-button {
        background-color: #28a745;
        color: white;
        border: none;
        padding: 0.5rem 1rem;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background-color: #218838;
        }
      }

      .cancel-button {
        background-color: #d9534f;
        color: white;
        border: none;
        padding: 0.5rem 1rem;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background-color: #c9302c;
        }
      }
    }
  }
}

.modal-content p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 1rem;

  strong {
    color: #007bff;
  }
}

.password-group {
  margin-top: 16px;
  margin-bottom: 24px;
}

.password-label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #333;
}

.password-input {
  width: 100%;
  padding: 12px 16px;
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  transition:
    border-color 0.3s ease,
    background-color 0.3s ease;

  &:focus {
    border-color: #007bff; /* Cor azul para o foco */
    background-color: #fff; /* Cor de fundo ao focar */
    outline: none;
  }

  &::placeholder {
    color: #aaa;
  }
}
