@import './styles/variables';
@import './styles/mixins';

/* Estilos Globais */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: $font-family;
  font-size: $font-size-base;
  color: $text-color;
  background-color: $secondary-color;
}
